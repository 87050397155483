<template>
    <footer class="footer-section">
        <div class="container">
            <div class="footer-cta pt-80 pb-20">
                <div class="row">
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fa fa-map-marker"></i>
                            <div class="cta-text">
                                <h4>Find us</h4>
                                <span>Office No. 11, Komal Complex,<br>Plot No. 305, Ward 12/B,<br> Gandhidham (Kutch), Gujarat, India.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fa fa-phone"></i>
                            <div class="cta-text">
                                <h4>Call us</h4>
                                <span>+91 9898 036208</span><br>
                                <span>+91 9723 236208</span><br>
                                <span>+91 9662 623471</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fa fa-envelope-open"></i>
                            <div class="cta-text">
                                <h4>Mail us</h4>
                                <span>sales@xtrm.co.in</span><br>
                                <span>info@xtrm.co.in</span><br>
                                <span>support@xtrm.co.in</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-content pt-50 pb-30">
                <div class="footer-pattern">
                    <img src="assets/img/footer/footer_map.png" alt="">
                </div>
                <div class="row">
                    <div class="col-xl-4 col-lg-4 mb-50">
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <!-- <a href="/">
                                   <img src="assets/img/logo/extreme solutions logo light.png" class="img-fluid" alt="Extreme logo">
                                </a> -->
                                <a href="javascript:void(0)" @click="$router.push('/')">
                                    <img src="../assets/img/logo/extreme-solutions-logo-light.png" class="img-fluid" alt="Extreme logo">
                                </a>
                            </div>
                            <div class="footer-text">
                                <p>We are Software developers. We develop customized and packaged software applications for various industry verticals like Manufacturing.</p>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                                <li>
                                    <!-- <a href="/">Home</a> -->
                                    <a href="javascript:void(0)" @click="$router.push('/')">Home</a>
                                </li>
                                <li>
                                    <!-- <a href="/about">About us</a> -->
                                    <a href="javascript:void(0)" @click="$router.push('/about')">Company</a>
                                </li>
                                <li>
                                    <!-- <a href="/services">Services</a> -->
                                    <a href="javascript:void(0)" @click="$router.push('/services')">Solutions</a>
                                </li>
                                <li>
                                    <!-- <a href="/products">Products</a> -->
                                    <a href="javascript:void(0)" @click="$router.push('/products')">Products</a>
                                </li>
                                <li>
                                    <!-- <a href="/support">Support</a> -->
                                    <a href="javascript:void(0)" @click="$router.push('/support');">Support</a>
                                </li>
                                <li>
                                    <!-- <a href="/support">Support</a> -->
                                    <a href="javascript:void(0)" @click="$router.push('/downloads');">Downloads</a>
                                </li>
                                <li>
                                    <!-- <a href="/contact">Contact</a> -->
                                    <a href="javascript:void(0)" @click="$router.push('/contact')">Contact</a>
                                </li>
                                <li>
                                    <!-- <a href="/becomepartner">Become a partner</a> -->
                                    <a href="javascript:void(0)" @click="$router.push('/becomepartner')">Become a partner</a>
                                </li>
                                <li>
                                    <!-- <a href="/career">Career</a> -->
                                    <a href="javascript:void(0)" @click="$router.push('/career')">Career</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div class="footer-social-icon">
                            <span>Follow us</span>
                            <a href="https://www.facebook.com/extremesolutions.in/"><i class="fa fa-facebook-f facebook-bg"></i></a>
                            <a href="https://twitter.com/extremetwitts"><i class="fa fa-twitter twitter-bg"></i></a>
                            <a href="https://www.youtube.com/c/ExtremeSolutionsGandhidham"><i class="fa fa-youtube google-bg"></i></a>
                            <a href="https://www.instagram.com/xtrmcoin"><i class="fa fa-instagram instagram-bg"></i></a>
                            <!-- <a href="#"><i class="fa fa-instagram google-bg"></i></a>
                            <a href="#"><i class="fa fa-linkedin-square facebook-bg"></i></a> -->
                        </div>
                        <!-- <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Subscribe</h3>
                            </div>
                            <div class="footer-text mb-25">
                                <p>Don’t miss to subscribe to our new feeds.</p>
                            </div>
                            <div id="mc_embed_signup" class="subscribe-form">
                                <form action="" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                    <div id="mc_embed_signup_scroll">
                                    <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required>
                                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_307862c135e4548eecf86f389_342411fb35" tabindex="-1" value=""></div>
                                    <div class="clear"><button type="submit" name="subscribe" id="mc-embedded-subscribe" class="button"><i class="fa fa-paper-plane"></i></button></div>
                                    </div>
                                </form>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 text-center text-lg-left">
                        <div class="copyright-text">
                            <p>Copyright &copy; {{mYear}}, All Right Reserved <a href="https://xtrm.co.in">Extreme Solutions</a></p>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right"></div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
   name: 'footersection',
   data(){
    return {
        mYear:null
    }
   },
   mounted(){
    this.mYear=new Date().getFullYear();
   }
}
</script>

<style lang="scss" scoped>

</style>