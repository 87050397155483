import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import JQuery from 'jquery'
window.$ = window.JQuery = JQuery
import VueSlick from 'vue-slick'
console.log(VueSlick)

const myApp = createApp(App)
// import VPagination from "vue3-pagination";
// import "vue3-pagination/dist/vue3-pagination.css";
// myApp.use(VPagination);

// import sweet alert
import VueSweetalert2 from 'vue-sweetalert2';
myApp.use(VueSweetalert2);
import swal from 'sweetalert2';
window.Swal = swal;

import './assets/css/bootstrap.min.css'
import './assets/css/slick.css'
import './assets/css/slick-theme.min.css'
import './assets/font-awesome/css/font-awesome.min.css'
import './assets/css/linearicons.css'
import './assets/css/icon-font.min.css'
import './assets/css/owl.carousel.min.css'
import './assets/css/animate.min.css'
import './assets/css/magnific-popup.css'
import './assets/css/meanmenu.css'
import './assets/css/default.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import './assets/css/sweetalert2.min.css'

import './assets/js/vendor/jquery-1.12.4.min.js'
import './assets/js/popper.min.js'
import './assets/js/bootstrap.min.js'
import './assets/js/jquery.meanmenu.min.js'
import './assets/js/slick.js'
import './assets/js/isotope.pkgd.min.js'
import './assets/js/imagesloaded.pkgd.min.js'
import './assets/js/jquery.waypoints.min.js'
import './assets/js/jquery.counterup.min.js'
import './assets/js/owl.carousel.min.js'
// import './assets/js/jquery.magnific-popup.min.js'
import './assets/js/scrollup.min.js'
import './assets/js/simple-jekyll-search.min.js'
import './assets/js/main.js'
import './assets/js/smtp.js'
import './assets/js/sweetalert2.all.min.js'
import './assets/js/SendMail.js'

createApp(App).use(router).mount('#app')