import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import Tutoriales from '../views/tutoriales'
import Frame from '../layouts/Frame.vue'

const routes = [
  {
    path: "",
    component: Frame,
    children: [
      // route for home
      { path: "", component: Home },
      // route for about
      { path: "about", component: () => import('../views/About.vue') },
      // routes for products
      { path: "products", component: () => import('../views/products/products.vue') },
      { path: "freightman", component: () => import('../views/products/freightman.vue') },
      { path: "tradebazix", component: () => import('../views/products/tradebazix.vue') },
      { path: "bizbank", component: () => import('../views/products/bizbank.vue') },
      { path: "cargomanager", component: () => import('../views/products/cargomanager.vue') },
      { path: "eximax", component: () => import('../views/products/eximax.vue') },
      { path: "foodnote", component: () => import('../views/products/foodnote.vue') },
      { path: "logmagix", component: () => import('../views/products/logmagix.vue') },
      { path: "schememanager", component: () => import('../views/products/schememanager.vue') },
      { path: "stockoutlook", component: () => import('../views/products/stockoutlook.vue') },
      { path: "visualshipment", component: () => import('../views/products/visualshipment.vue') },
      // routes for services
      { path: "services", component: () => import('../views/services/services.vue') },
      { path: "softwaredevelopment", component: () => import('../views/services/softwaredevelopment.vue') },
      { path: "websitedevelopment", component: () => import('../views/services/websitedevelopment.vue') },
      { path: "domainregistration", component: () => import('../views/services/domainregistration.vue') },
      { path: "webemailhosting", component: () => import('../views/services/webemailhosting.vue') },
      { path: "bulksms", component: () => import('../views/services/bulksms.vue') },
      { path: "digitalmarketing", component: () => import('../views/services/digitalmarketing.vue') },
      // routes for support
      { path: "support", component: () => import('../views/support/support.vue') },
      // routes for downloads
      { path: "downloads", component: () => import('../views/support/downloads.vue') },
      // route for become partner
      { path: "becomepartner", component: () => import('../views/becomepartner.vue') },
      // route for contact
      { path: "contact", component: () => import('../views/contact.vue') },
      // route for career
      { path: "career", component: () => import('../views/career.vue') },
      // { path: "tutoriales/:id", component: Tutoriales },
      { path: "tutoriales/:id", component: () => import('../views/tutoriales.vue') },
      { path: "pagination", component: () => import('../views/support/pagination.vue') },
    ],
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior () {
    // scrollBehavior (to, from, savedPosition) {
    // return desired position
    // console.log(to, from, savedPosition);
    return { top: 0 };
  }
})

export default router
