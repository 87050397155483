<template>
	<!-- <Frame /> -->
	<!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
	<router-view />
</template>

<script>
// import Frame from "./layouts/Frame";
export default {
	name: "App",
	components: {
		// Frame,
	},
};
</script>

<style>
</style>
