<template>
	<div>
		<div class="header-top blue-bg">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-xl-8 col-lg-8 col-md-8 text-center text-md-left">
						<div class="header-top-cta">
							<span
								><i class="fa fa-map-marker"></i>Office No. 11, Komal Complex,
								Gandhidham</span
							>
							<span><i class="fa fa-envelope-o"></i>info@xtrm.co.in</span>
							<span><i class="fa fa-phone"></i>+91 9898 036208</span>
						</div>
					</div>
					<div class="col-xl-4 col-lg-4 col-md-4 text-center text-md-right">
						<div class="top-social-links">
							<span>Follow us on : </span>
							<a href="https://www.facebook.com/extremesolutions.in/"><i class="fa fa-facebook-f"></i></a>
							<a href="https://twitter.com/extremetwitts"><i class="fa fa-twitter"></i></a>
							<a href="https://www.youtube.com/c/ExtremeSolutionsGandhidham"><i class="fa fa-youtube"></i></a>
							<a href="https://www.instagram.com/xtrmcoin"><i class="fa fa-instagram"></i></a>
							<!-- <a href="#"><i class="fa fa-instagram"></i></a>
							<a href="#"><i class="fa fa-linkedin-square"></i></a> -->
							<div class="top-btn">
								<!-- <a href="/career">Career</a> -->
								<a href="javascript:void(0)" @click="$router.push('/career')">Career</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="sticky-header" class="menu-area">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-xl-2 col-lg-2 col-md-3 col-sm-5">
						<div class="logo">
							<!-- <a href="/"
								><img
									src="assets/img/logo/Extreme-logo.jpg"
									class="img-fluid"
									alt="Extreme Solutions Logo"
							/></a> -->
							<a href="javascript:void(0)" @click="$router.push('/')">
								<img
									src="../assets/img/logo/Extreme-logo.png"
									class="img-fluid"
									alt="Extreme Solutions Logo"
							    />
							</a>
						</div>
					</div>
					<div class="col-xl-10 col-lg-10 text-right">
						<div class="main-menu">
							<nav id="mobile-menu">
								<ul>
									<li>
										<!-- <a href="/">Home</a> -->
										<router-link to="/">Home</router-link>
										<!-- <a href="javascript:void(0)" @click="$router.push('/')">Home</a> -->
									</li>
									<li>
										<!-- <a href="/about">About us</a> -->
										<router-link to="/about">Company</router-link>
										<!-- <a href="javascript:void(0)" @click="$router.push('/about')">Company</a> -->
									</li>
									<li>
										<!-- <a href="#"
											>Products<i
												class="fa fa-angle-down arrow-icon"
												aria-hidden="true"
											></i>
										</a> -->
										<router-link to="/products">Products<i class="fa fa-angle-down arrow-icon"></i></router-link>
										<!-- <a href="javascript:void(0)" @click="$router.push('/products')">
											Products<i class="fa fa-angle-down arrow-icon" aria-hidden="true"></i>
										</a> -->
										<ul class="sub-menu">
											<li>
												<!-- <a href="/freightman">FreightMan</a> -->
												<router-link to="/freightman">FreightMan</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/freightman')">FreightMan</a> -->
											</li>
											<li>
												<!-- <a href="/tradebazix">TradeBazix</a> -->
												<router-link to="/tradebazix">TradeBazix</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/tradebazix')">TradeBazix</a> -->
											</li>
											<li>
												<!-- <a href="/bizbank">BizBank</a> -->
												<router-link to="/bizbank">BizBank</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/bizbank')">BizBank</a> -->
											</li>
											<li>
												<!-- <a href="/cargomanager">Cargo Manager</a> -->
												<router-link to="/cargomanager">Cargo Manager</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/cargomanager')">Cargo Manager</a> -->
											</li>
											<li>
												<!-- <a href="/eximax">EXIMax</a> -->
												<router-link to="/eximax">EXIMax</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/eximax')">EXIMax</a> -->
											</li>
											<li>
												<!-- <a href="/foodnote">FoodNote</a> -->
												<router-link to="/foodnote">FoodNote</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/foodnote')">FoodNote</a> -->
											</li>
											<li>
												<!-- <a href="/logmagix">LogMagix</a> -->
												<router-link to="/logmagix">LogMagix</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/logmagix')">LogMagix</a> -->
											</li>
											<li>
												<!-- <a href="/schememanager">Scheme Manager</a> -->
												<router-link to="/schememanager">Scheme Manager</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/schememanager')">Scheme Manager</a> -->
											</li>
											<li>
												<!-- <a href="/stockoutlook">Stock Outlook</a> -->
												<router-link to="/stockoutlook">Stock Outlook</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/stockoutlook')">Stock Outlook</a> -->
											</li>
											<li>
												<!-- <a href="/visualshipment">Visual Shipment</a> -->
												<router-link to="/visualshipment">Visual Shipment</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/visualshipment')">Visual Shipment</a> -->
											</li>
										</ul>
									</li>
									<li>
										<!-- <a href="#"
											>Services<i
												class="fa fa-angle-down arrow-icon"
												aria-hidden="true"
											></i
										></a> -->
										<router-link to="/services">Solutions<i class="fa fa-angle-down arrow-icon" aria-hidden="true"></i></router-link>
										<!-- <a href="javascript:void(0)" @click="$router.push('/services')">
											Solutions<i class="fa fa-angle-down arrow-icon" aria-hidden="true"></i>
										</a> -->
										<ul class="sub-menu">
											<li>
												<!-- <a href="/softwaredevelopment">Software Development</a> -->
												<router-link to="/softwaredevelopment">Software Development</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/softwaredevelopment')">Software Development</a> -->
											</li>
											<li>
												<!-- <a href="/websitedevelopment">Website Development</a> -->
												<router-link to="/websitedevelopment">Website Development</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/websitedevelopment')">Website Development</a> -->
											</li>
											<li>
												<!-- <a href="/domainregistration">Domain Registration</a> -->
												<router-link to="/domainregistration">Domain Registration</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/domainregistration')">Domain Registration</a> -->
											</li>
											<li>
												<!-- <a href="/webemailhosting">Web/Email Hosting</a> -->
												<router-link to="/webemailhosting">Web/Email Hosting</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/webemailhosting')">Web/Email Hosting</a> -->
											</li>
											<li>
												<!-- <a href="/bulksms">Bulk SMS</a> -->
												<router-link to="/bulksms">Bulk SMS</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/bulksms')">Bulk SMS</a> -->
											</li>
											<li>
												<!-- <a href="/digitalmarketing">Digital Marketing</a> -->
												<router-link to="/digitalmarketing">Digital Marketing</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/digitalmarketing')">Digital Marketing</a> -->
											</li>
										</ul>
									</li>
									<li>
										<!-- <a href="#"
											>Support<i
												class="fa fa-angle-down arrow-icon"
												aria-hidden="true"
											></i
										></a> -->
										<router-link to="/support">Support<i class="fa fa-angle-down arrow-icon" aria-hidden="true"></i></router-link>
										<!-- <a href="javascript:void(0)" @click="$router.push('/support')">
											Support<i class="fa fa-angle-down arrow-icon" aria-hidden="true"></i>
										</a> -->
										<ul class="sub-menu">
											<li>
												<!-- <a href="/support">Video Tutorials</a> -->
												<router-link to="/support">Video Tutorials</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/support')">Video Tutorials</a> -->
											</li>
											<li>
												<!-- <router-link to="/tradebazix">TradeBazix</router-link> -->
												<a href="https://support.zoho.com/portal/teamxtrm/newticket">Submit a Ticket</a>
												<!-- <a href="javascript:void(0)" @click="$router.push('#')">Submit a Ticket</a> -->
											</li>
											<li>
												<!-- <router-link to="/tradebazix">TradeBazix</router-link> -->
												<a href="https://support.zoho.com/portal/teamxtrm/signin">Sign Up / Sign In</a>
												<!-- <a href="javascript:void(0)" @click="$router.push('#')">Sign Up / Sign In</a> -->
											</li>
											<li>
												<router-link to="/downloads">Downloads</router-link>
												<!-- <a href="javascript:void(0)" @click="$router.push('/downloads')">Downloads</a> -->
												<!-- <a href="javascript:void(0)" @click="$router.push('#')">Downloads</a> -->
											</li>
										</ul>
									</li>
									<li>
										<!-- <a href="/becomepartner">Become a partner</a> -->
										<router-link to="/becomepartner">Become a partner</router-link>
										<!-- <a href="javascript:void(0)" @click="$router.push('/becomepartner')">Become a partner</a> -->
									</li>
									<li>
										<!-- <a href="/contact">Contact</a> -->
										<router-link to="/contact">Contact</router-link>
										<!-- <a href="javascript:void(0)" @click="$router.push('/contact')">Contact</a> -->
									</li>
								</ul>
							</nav>
						</div>
					</div>
					<div class="col-12">
						<div class="mobile-menu"></div>
					</div>
				</div>
				<div class="offcanvas-overly"></div>
			</div>
		</div>
	</div>
</template>

<script>
// var script = document.createElement('script');
// script.src = "../assets/js/jquery.meanmenu.min.js";
// document.head.appendChild(script)
// import {meanmenu} from '../assets/js/jquery.meanmenu.min.js';
import $ from 'jquery'
import '../assets/js/jquery.meanmenu.min.js'

export default {
	name: "headersection",
	mounted() {
		"use strict";
		// mobile menu
		$("#mobile-menu").meanmenu({
			meanMenuContainer: ".mobile-menu",
			meanScreenWidth: "991",
		});

		// sticky
		var wind = $(window);
		var sticky = $("#sticky-header");
		wind.on("scroll", function() {
			var scroll = wind.scrollTop();
			if (scroll < 180) {
				sticky.removeClass("sticky");
			} else {
				sticky.addClass("sticky");
			}
		});

		// offcanvas menu
		$(".menu-tigger").on("click", function() {
			$(".offcanvas-menu,.offcanvas-overly").addClass("active");
			return false;
		});
		$(".menu-close,.offcanvas-overly").on("click", function() {
			$(".offcanvas-menu,.offcanvas-overly").removeClass("active");
		});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.arrow-icon {
	padding-left: 10px;
	font-weight: bold;
}
</style>
