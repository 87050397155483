<template>
	<main>
		<section class="slider-area" style="background-size:cover;">
			<header class="hero-text">
				<div class="hero" data-arrows="true" data-autoplay="true">
				<!--.hero-slide-->
				
				<div class="hero-slide">
					<img alt="Mars Image" class="img-fluid cover" src="../assets/img/slider/slider1.jpg">
					<div class="header-content text-white position-absolute slide-content col-lg-4">
						<h1 class="mb-4">WE ACT FAST <span class="d-block font-weight-bold slider-sub-title">AND DELIVER THE BEST</span></h1> 
						<p class="font-weight-bold">
							We will help you develop customized software applications
							and assist you throughout the entire software<br />
							development Life Cycle including project management systems
							analysis and design.
						</p> 
						<a class="btn btn-primary btn-lg w-max mt-2" href="javascript:void(0)" @click="$router.push('/products')" tabindex="0">Contact Us</a>
					</div>
				</div>
				<!--.hero-slide-->
				
				<div class="hero-slide">
					<img alt="Mars Image" class="img-fluid cover" src="../assets/img/slider/slider2.jpg">
					<div class="header-content text-white position-absolute slide-content col-lg-4">
							<h1 class="mb-4">WE ACT FAST <span class="d-block font-weight-bold slider-sub-title">AND DELIVER THE BEST</span></h1> 
						<p class="font-weight-bold">
							We will help you develop customized software applications
							and assist you throughout the entire software<br />
							development Life Cycle including project management systems
							analysis and design.
						</p> 
							<a class="btn btn-primary btn-lg w-max mt-2" href="javascript:void(0)" @click="$router.push('/products')" tabindex="0">Contact Us</a>
						</div>
				</div>
				<!--.hero-slide-->
				
				<div class="hero-slide">
					<img alt="Mars Image" class="img-fluid cover" src="../assets/img/slider/slider3.jpg">
					<div class="header-content text-white position-absolute slide-content col-lg-4">
						    <h1 class="mb-4">WE ACT FAST <span class="d-block font-weight-bold slider-sub-title">AND DELIVER THE BEST</span></h1> 
						<p class="font-weight-bold">
							We will help you develop customized software applications
							and assist you throughout the entire software<br />
							development Life Cycle including project management systems
							analysis and design.
						</p>   
							<a class="btn btn-primary btn-lg w-max mt-2" href="javascript:void(0)" @click="$router.push('/products')" tabindex="0">Contact Us</a>
						</div>
				</div>
				</div><!--.hero-->
			</header>
		</section>
		<section class="about-area pb-60 pt-100">
			<div class="container">
				<div class="row">
					<div class="col-xl-6 col-lg-6 mb-40">
						<div class="section-title">
							<h2>About Us</h2>
						</div>
						<div class="about-content">
							<p>
								We are Software developers. We develop customized and packaged
								software applications for various industry verticals like
								Manufacturing, Distribution and Trading, Retail, Cargo &
								transport on various technology platforms. We have expertise on
								Microsoft platform and embedded programming. We have tie-ups
								with world majors and work on future technology. <br />
								<br />We have experience, skill and proven track record to
								undertake Enterprise-wide network & client-server applications
								development, Web Applications and services development, Software
								Product maintenance - pre and post implementation support,
								Software Product development - from concept to prototype and
								final product.
							</p>
						</div>
					</div>
					<div class="col-xl-6 col-lg-6 mb-20 md-margin text-center">
						<img src="assets/img/about/xtrm.jpg" class="img-fluid" alt="" />
					</div>
				</div>
			</div>
		</section>
		<section class="service-area grey-bg pb-70 pt-100">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center mb-40">
						<div class="section-title service-title">
							<h2>Our Products</h2>
							<p>We work on ideas that work for you.</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/truck.svg" alt="FreightMan" />
							</div>

							<h4>FreightMan</h4>
							<p>
								FreightMan is windows based solution, for transport companies.
								The system is highly configurable to provide flexibility and at
								the same time can also be tailored to meet your precise needs.
							</p>
							<div class="pt-25">
								<!-- <a href="/freightman">Read More</a> -->
								<a href="javascript:void(0)" @click="$router.push('/freightman')">Read More</a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/gasoline-pump.svg" alt="TRADEBAZIX" />
							</div>

							<h4>TRADEBAZIX</h4>
							<p>
								Whether you are a wholesaler or retailer or run a super market
								or operate a petrol pump, TradeBazix is the right solution for
								you. Track inventory and manage goods as they come in and go
								out.
							</p>
							<div class="pt-25">
								<!-- <a href="/tradebazix">Read More</a> -->
								<a href="javascript:void(0)" @click="$router.push('/tradebazix')">Read More</a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/ship.svg" alt="EXIMAX" />
							</div>

							<h4>EXIMAX</h4>
							<p>
								complete accounting software for Custom House Agents. EXIMax
								covers all the accounting and financial requirements of a
								shipping business. A simple, convenient and affordable solution
								that helps you manage.
							</p>
							<!-- <a href="/eximax">Read More</a> -->
							<a href="javascript:void(0)" @click="$router.push('/eximax')">Read More</a>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/fast-food.svg" alt="FOODNOTE" />
							</div>

							<h4>FOODNOTE</h4>
							<p>
								A restaurant management software. FoodNote is our latest and
								highly configurable solution for hoteliers and restaurant
								owners. Just like your customers who get addicted to taste of
								your food.
							</p>
							<!-- <a href="/foodnote">Read More</a> -->
							<a href="javascript:void(0)" @click="$router.push('/foodnote')">Read More</a>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/water-truck.svg" alt="Cargo Manager" />
							</div>

							<h4>Cargo Manager</h4>
							<p>
								An inventory management software for liquid cargo terminals.
								Allocate transporters for outward transportation of cargo and
								generate gate pass based on weighment received from weighbridge.
							</p>
							<div class="pt-25">
								<!-- <a href="/cargomanager">Read More</a> -->
								<a href="javascript:void(0)" @click="$router.push('/cargomanager')">Read More</a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/building.svg" alt="BizBank" />
							</div>

							<h4>BizBank</h4>
							<p>
								Manage procurements, Production, Distribution and Accounting all
								in a single integrated software system. Track business resources
								cash, raw materials, production capacity and the status of
								business.
							</p>
							<!-- <a href="/bizbank">Read More</a> -->
							<a href="javascript:void(0)" @click="$router.push('/bizbank')">Read More</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section
			class="cta-area"
			style="background-image:url(assets/img/bg/cta1.jpg);"
		>
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center">
						<div class="call-to-action white-text">
							<h3>Looking for more products ?</h3>
							<div class="call-to-action">
								<!-- <a href="/products" class="btn video-view">Find Theme Here</a> -->
								<a href="javascript:void(0)" class="btn video-view" @click="$router.push('/products')">Find Theme Here</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="service-area pt-100 pb-50">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center pb-40 ">
						<div class="section-title service-title">
							<h2>Our Services</h2>
							<p>
								We tailor our service to fit your budget, paying only for what
								you need.
							</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-xl-4 col-lg-4 col-md-6 mb-50 ">
						<div class="services-box">
							<div class="services-img">
								<img
									src="assets/img/service/1.jpg"
									class="img-fluid"
									alt="Dedicated support"
								/>
							</div>
							<div class="services-content">
								<div class="card-title">
									<h5>Domain Registration</h5>
								</div>
								<div class="card-text">
									<p>
										At Extreme Solutions, with an immense domain knowledge, we
										have transformed ourselves into the foremost provider of
										quality, cost effective domain registration services.
									</p>
								</div>
								<!-- <a href="/domainregistration" class="btn">Read More</a> -->
								<a href="javascript:void(0)" class="btn" @click="$router.push('/domainregistration')">Read More</a>
							</div>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-6 mb-50 ">
						<div class="services-box">
							<div class="services-img">
								<img
									src="assets/img/service/2.jpg"
									class="img-fluid"
									alt="Designs & interfaces"
								/>
							</div>
							<div class="services-content">
								<div class="card-title">
									<h5>Software Development</h5>
								</div>
								<div class="card-text">
									<p>
										We offer a rich repertoire of software services to our
										clients seeking customized software solutions. Be it
										complete office automation or database driven solutions.
									</p>
								</div>
								<div class="pt-25">
									<!-- <a href="/softwaredevelopment" class="btn">Read More</a> -->
									<a href="javascript:void(0)" class="btn" @click="$router.push('/softwaredevelopment')">Read More</a>
								</div>
							</div>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-6 mb-50 ">
						<div class="services-box">
							<div class="services-img">
								<img
									src="assets/img/service/3.jpg"
									class="img-fluid"
									alt="Features & plugins"
								/>
							</div>
							<div class="services-content">
								<div class="card-title">
									<h5>Website Development</h5>
								</div>
								<div class="card-text">
									<p>
										Our expertise in web technology contributes to add beauty
										and simplicity to our web solutions to enable our clients’
										business more efficient. We provide best web development
										services.
									</p>
								</div>
								<!-- <a href="/websitedevelopment" class="btn">Read More</a> -->
								<a href="javascript:void(0)" class="btn" @click="$router.push('/websitedevelopment')">Read More</a>
							</div>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-6 mb-50 ">
						<div class="services-box">
							<div class="services-img">
								<img
									src="assets/img/service/4.jpg"
									class="img-fluid"
									alt="Highly customizable"
								/>
							</div>
							<div class="services-content">
								<div class="card-title">
									<h5>Web / Email Hosting</h5>
								</div>
								<div class="card-text">
									<p>
										Our flexible and feature-packed Web hosting plans give you
										everything you need to easily launch your website. You will
										get a secure Web hosting environment you can trust.
									</p>
								</div>
								<!-- <a href="/webemailhosting" class="btn">Read More</a> -->
								<a href="javascript:void(0)" class="btn" @click="$router.push('/webemailhosting')">Read More</a>
							</div>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-6 mb-50 ">
						<div class="services-box">
							<div class="services-img">
								<img
									src="assets/img/service/5.jpg"
									class="img-fluid"
									alt="Optimised for speed"
								/>
							</div>
							<div class="services-content">
								<div class="card-title">
									<h5>Bulk SMS</h5>
								</div>
								<div class="card-text">
									<p>
										Our cost effective and flexible Bulk SMS plans have helped
										our customers stay connected with their customers or
										potential buyers. We offer various packages for bulk SMS.
									</p>
								</div>
								<!-- <a href="/bulksms" class="btn">Read More</a> -->
								<a href="javascript:void(0)" class="btn" @click="$router.push('/bulksms')">Read More</a>
							</div>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-6 mb-50 ">
						<div class="services-box">
							<div class="services-img">
								<img
									src="assets/img/service/6.jpg"
									class="img-fluid"
									alt="Responsive design"
								/>
							</div>
							<div class="services-content">
								<div class="card-title">
									<h5>Digital Marketing</h5>
								</div>
								<div class="card-text">
									<p>
										Digital marketing refers to advertising delivered through
										digital channels such as search engines, websites, social
										media, email, and mobile apps.
									</p>
								</div>
								<!-- <a href="/digitalmarketing" class="btn">Read More</a> -->
								<a href="javascript:void(0)" class="btn" @click="$router.push('/digitalmarketing')">Read More</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div
			class="counter-area pb-100 pt-100"
			data-overlay="7"
			style="background-image:url(assets/img/counterBg/bg1.jpg);"
		>
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center z-index">
						<div class="call-to-action white-text">
							<h3>Looking for more services ?</h3>
							<div class="call-to-action">
								<!-- <a href="/services" class="btn video-view">Find Theme Here</a> -->
								<a href="javascript:void(0)" class="btn video-view" @click="$router.push('/services')">Find Theme Here</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- slick carusal slider -->
		<section class="blog-area grey-bg pt-100 pb-70">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center pb-40">
						<div class="section-title service-title">
							<h2>Latest Blog</h2>
							<p>Stay connected ! Stay updated ! Get educated !</p>
						</div>
					</div>
				</div>
				<div class="row product-slider">
					<div class="col-md-12">
						<div class="blog-wrapper home-blog-wrapper white-bg">
							<div class="blog-thumb">
								<iframe
									width="100%"
									frameborder="0"
									allowfullscreen
									src="https://www.youtube.com/embed/xhQrlmiPlp8?feature=oembed"
								>
								</iframe>
							</div>
							<div class="meta-info">
								<ul>
									<li class="posts-time">05 March 2021</li>
								</ul>
							</div>
							<div class="blog-content home-blog">
								<h2 class="blog-title">
									<a href="#">Vehicle Expenses And Reminders</a>
								</h2>
								<p>
									This video tutorial explains how to setup vehicle reminders
									and how to enter vehicle expenses in FreightMan as well as
									Fleet Manager. अगर आप Video नहीं देखना चाहते, या आपको Video
									पसंद नहीं आया तो आप चाहें तो इस Tutorial के PDF Version को
									Download भी कर शकते हैं।
								</p>
							</div>
							<div class="link-box home-blog-link">
								<a href="#" class="btn-2">Download PDF</a>
							</div>
						</div>
					</div>

					<div class="col-md-12">
						<div class="blog-wrapper home-blog-wrapper white-bg">
							<div class="blog-thumb">
								<iframe
									width="100%"
									frameborder="0"
									allowfullscreen
									src="https://www.youtube.com/embed/hDVh7FcVL6M?feature=oembed"
								>
								</iframe>
							</div>
							<div class="meta-info">
								<ul>
									<li class="posts-time">05 March 2021</li>
								</ul>
							</div>
							<div class="blog-content home-blog">
								<h2 class="blog-title">
									<a href="#">Inventory And Workshop Management</a>
								</h2>
								<p>
									This video tutorial explains how to carry Inventory and
									Workshop Management in FreightMan as well as Fleet Manager.
									अगर आप Video नहीं देखना चाहते, या आपको Video पसंद नहीं आया तो
									आप चाहें तो इस Tutorial के PDF Version को Download भी कर शकते
									हैं।
								</p>
							</div>
							<div class="link-box home-blog-link">
								<a href="https://drive.google.com/file/d/0B0LD7OXv0fCcNklqaHNCaTNYNlk/view?resourcekey=0-HsiHqpcE94EwlkJxe7U6UA" class="btn-2">Download PDF</a>
							</div>
						</div>
					</div>

					<div class="col-md-12">
						<div class="blog-wrapper home-blog-wrapper white-bg">
							<div class="blog-thumb">
								<iframe
									width="100%"
									frameborder="0"
									allowfullscreen
									src="https://www.youtube.com/embed/VSukBMA7MSw?feature=oembed"
								>
								</iframe>
							</div>
							<div class="meta-info">
								<ul>
									<li class="posts-time">05 March 2021</li>
								</ul>
							</div>
							<div class="blog-content home-blog">
								<h2 class="blog-title">
									<a href="#">How To Create A Journal Voucher ?</a>
								</h2>
								<p>
									This video tutorial explains how to create a journal voucher
									in FreightMan. अगर आप Video नहीं देखना चाहते, या आपको Video
									पसंद नहीं आया तो आप चाहें तो इस Tutorial के PDF Version को
									Download भी कर शकते हैं।
								</p>
							</div>
							<div class="link-box home-blog-link">
								<a href="https://drive.google.com/file/d/0B0LD7OXv0fCcZk91dDJWNnhOSkE/view?resourcekey=0-BZHepoUsCj86puHBNDuiOA" class="btn-2">Download PDF</a>
							</div>
						</div>
					</div>

					<div class="col-md-12">
						<div class="blog-wrapper home-blog-wrapper white-bg">
							<div class="blog-thumb">
								<iframe
									width="100%"
									frameborder="0"
									allowfullscreen
									src="https://www.youtube.com/embed/5NFV_BrXJo4?feature=oembed"
								>
								</iframe>
							</div>
							<div class="meta-info">
								<ul>
									<li class="posts-time">05 March 2021</li>
								</ul>
							</div>
							<div class="blog-content home-blog">
								<h2 class="blog-title">
									<a href="#">How To Create Rate List ?</a>
								</h2>
								<p>
									This video tutorial explains how to create a rate-list and in
									case if there are multiple rate-lists for the same trip how
									would they be prioritized in FreightMan. अगर आप Video नहीं
									देखना चाहते, या आपको Video पसंद नहीं आया तो आप चाहें तो इस
									Tutorial के PDF Version को Download भी कर शकते हैं।
								</p>
							</div>
							<div class="link-box home-blog-link">
								<a href="https://drive.google.com/file/d/0B0LD7OXv0fCcSU5QYmoxeTQxcUU/view?resourcekey=0-CWbmsKPCae-8iYkbMp1jBQ" class="btn-2">Download PDF</a>
							</div>
						</div>
					</div>

					<div class="col-md-12">
						<div class="blog-wrapper home-blog-wrapper white-bg">
							<div class="blog-thumb">
								<iframe
									width="100%"
									frameborder="0"
									allowfullscreen
									src="https://www.youtube.com/embed/R_RZ9e6YBhQ?feature=oembed"
								>
								</iframe>
							</div>
							<div class="meta-info">
								<ul>
									<li class="posts-time">05 March 2021</li>
								</ul>
							</div>
							<div class="blog-content home-blog">
								<h2 class="blog-title">
									<a href="#">How To Enter Trip Expense ?</a>
								</h2>
								<p>
									This video tutorial explains how to enter Expenses incurred
									during one or more trips in FreightMan. अगर आप Video नहीं
									देखना चाहते, या आपको Video पसंद नहीं आया तो आप चाहें तो इस
									Tutorial के PDF Version को Download भी कर शकते हैं।
								</p>
							</div>
							<div class="link-box home-blog-link">
								<a href="https://drive.google.com/file/d/0B0LD7OXv0fCcb1FaR2FkbEhRUUE/view?resourcekey=0-bfPF8xnzqQzpCVqgMquHLg" class="btn-2">Download PDF</a>
							</div>
						</div>
					</div>

					<div class="col-md-12">
						<div class="blog-wrapper home-blog-wrapper white-bg">
							<div class="blog-thumb">
								<iframe
									width="100%"
									frameborder="0"
									allowfullscreen
									src="https://www.youtube.com/embed/U7kBoVJEJEM?feature=oembed"
								>
								</iframe>
							</div>
							<div class="meta-info">
								<ul>
									<li class="posts-time">05 March 2021</li>
								</ul>
							</div>
							<div class="blog-content home-blog">
								<h2 class="blog-title">
									<a href="#">How To Create Receipt/Payment Vouchers</a>
								</h2>
								<p>
									This video tutorial explains how to create a Receipt or
									Payment voucher in accounting software developed by Extreme
									Solutions. अगर आप Video नहीं देखना चाहते, या आपको Video पसंद
									नहीं आया तो आप चाहें तो इस Tutorial के PDF Version को Download
									भी कर शकते हैं।
								</p>
							</div>
							<div class="link-box home-blog-link">
								<a href="https://drive.google.com/file/d/0B0LD7OXv0fCcaXIxZE5hTzlIUWM/view?resourcekey=0-oPM01TiAfzmNW6tv6o6O_g" class="btn-2">Download PDF</a>
							</div>
						</div>
					</div>

					<div class="col-md-12">
						<div class="blog-wrapper home-blog-wrapper white-bg">
							<div class="blog-thumb">
								<iframe
									width="100%"
									frameborder="0"
									allowfullscreen
									src="https://www.youtube.com/embed/Ei0hpgOb-78?feature=oembed"
								>
								</iframe>
							</div>
							<div class="meta-info">
								<ul>
									<li class="posts-time">05 March 2021</li>
								</ul>
							</div>
							<div class="blog-content home-blog">
								<h2 class="blog-title">
									<a href="#"
										>How To Create A Freight And Detention Invoice ?</a
									>
								</h2>
								<p>
									This video tutorial explains how to create a Freight or
									Detention Invoice in FreightMan by Extreme Solutions. अगर आप
									Video नहीं देखना चाहते, या आपको Video पसंद नहीं आया तो आप
									चाहें तो इस Tutorial के PDF Version को Download भी कर शकते
									हैं।
								</p>
							</div>
							<div class="link-box home-blog-link">
								<a href="https://drive.google.com/file/d/0B0LD7OXv0fCcamJxeFpMRzV4YTg/view?resourcekey=0-4-QpcpoTZDzdA0D6X6qDrA" class="btn-2">Download PDF</a>
							</div>
						</div>
					</div>

					<div class="col-md-12">
						<div class="blog-wrapper home-blog-wrapper white-bg">
							<div class="blog-thumb">
								<iframe
									width="100%"
									frameborder="0"
									allowfullscreen
									src="https://www.youtube.com/embed/ttiAkz8-jaI?feature=oembed"
								>
								</iframe>
							</div>
							<div class="meta-info">
								<ul>
									<li class="posts-time">05 March 2021</li>
								</ul>
							</div>
							<div class="blog-content home-blog">
								<h2 class="blog-title">
									<a href="#">How To Create A Freight Statement ?</a>
								</h2>
								<p>
									This video tutorial explains how to create Freight Statement
									in FreightMan by Extreme Solutions. अगर आप Video नहीं देखना
									चाहते, या आपको Video पसंद नहीं आया तो आप चाहें तो इस Tutorial
									के PDF Version को Download भी कर शकते हैं।
								</p>
							</div>
							<div class="link-box home-blog-link">
								<a href="https://drive.google.com/file/d/0B0LD7OXv0fCcbnlmWEU5MTh5RFE/view?resourcekey=0-mJQVu8oUH2b_iNWmXmDQdg" class="btn-2">Download PDF</a>
							</div>
						</div>
					</div>

					<div class="col-md-12">
						<div class="blog-wrapper home-blog-wrapper white-bg">
							<div class="blog-thumb">
								<iframe
									width="100%"
									frameborder="0"
									allowfullscreen
									src="https://www.youtube.com/embed/VLAzKdnP7X8?feature=oembed"
								>
								</iframe>
							</div>
							<div class="meta-info">
								<ul>
									<li class="posts-time">05 March 2021</li>
								</ul>
							</div>
							<div class="blog-content home-blog">
								<h2 class="blog-title">
									<a href="#">Unloading Detailes: Get, Set, Go...</a>
								</h2>
								<p>
									This video tutorial explains how to enter Unloading Details in
									FreightMan by Extreme Solutions. अगर आप Video नहीं देखना
									चाहते, या आपको Video पसंद नहीं आया तो आप चाहें तो इस Tutorial
									के PDF Version को Download भी कर शकते हैं।
								</p>
							</div>
							<div class="link-box home-blog-link">
								<a href="https://drive.google.com/file/d/0B0LD7OXv0fCcekdtSGtTdEVpV1E/view?resourcekey=0-IHqxMJGieHaSE6t9JxcMrA" class="btn-2">Download PDF</a>
							</div>
						</div>
					</div>

					<div class="col-md-12">
						<div class="blog-wrapper home-blog-wrapper white-bg">
							<div class="blog-thumb">
								<iframe
									width="100%"
									frameborder="0"
									allowfullscreen
									src="https://www.youtube.com/embed/JNRWJ87IDgA?feature=oembed"
								>
								</iframe>
							</div>
							<div class="meta-info">
								<ul>
									<li class="posts-time">05 March 2021</li>
								</ul>
							</div>
							<div class="blog-content home-blog">
								<h2 class="blog-title">
									<a href="#">How ToCreate, Modify, Merge or Delete An Entry</a>
								</h2>
								<p>
									This video tutorial explains how to create, modify, merge or
									delete an entry in software developed by Extreme Solutions.
									अगर आप Video नहीं देखना चाहते, या आपको Video पसंद नहीं आया तो
									आप चाहें तो इस Tutorial के PDF Version को Download भी कर शकते
									हैं।
								</p>
							</div>
							<div class="link-box home-blog-link">
								<a href="https://drive.google.com/file/d/0B0LD7OXv0fCcWjRkV0k1VHFjNWc/view?resourcekey=0-j6VUOCMOU9ShO1weBDsFsA" class="btn-2">Download PDF</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="clients-area pt-50 pb-50">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center pb-40">
						<div class="section-title service-title">
							<h2>Our Clients</h2>
						</div>
					</div>
				</div>
				<!-- <div class="row clients-active">
					<div class="col-sm-12">
						<img src="assets/img/clients/client-1.jpg" alt="" />
					</div>
					<div class="col-sm-12">
						<img src="assets/img/clients/client-2.jpg" alt="" />
					</div>
					<div class="col-sm-12">
						<img src="assets/img/clients/client-3.jpg" alt="" />
					</div>
					<div class="col-sm-12">
						<img src="assets/img/clients/client-4.jpg" alt="" />
					</div>
					<div class="col-sm-12">
						<img src="assets/img/clients/client-5.jpg" alt="" />
					</div>
					<div class="col-sm-12">
						<img src="assets/img/clients/client-6.jpg" alt="" />
					</div>
					<div class="col-sm-12">
						<img src="assets/img/clients/client-1.jpg" alt="" />
					</div>
					<div class="col-sm-12">
						<img src="assets/img/clients/client-2.jpg" alt="" />
					</div>
					<div class="col-sm-12">
						<img src="assets/img/clients/client-3.jpg" alt="" />
					</div>
					<div class="col-sm-12">
						<img src="assets/img/clients/client-4.jpg" alt="" />
					</div>
					<div class="col-sm-12">
						<img src="assets/img/clients/client-5.jpg" alt="" />
					</div>
					<div class="col-sm-12">
						<img src="assets/img/clients/client-6.jpg" alt="" />
					</div>
				</div> -->
				<div class="row">
					<div class="clients-active owl-carousel">
						<div class="col-sm-12">
							<div class="single-clients">
								<img src="assets/img/clients/client-1.jpg" alt="" />
							</div>
						</div>
						<div class="col-sm-12">
							<div class="single-clients">
								<img src="assets/img/clients/client-2.jpg" alt="" />
							</div>
						</div>
						<div class="col-sm-12">
							<div class="single-clients">
								<img src="assets/img/clients/client-3.jpg" alt="" />
							</div>
						</div>
						<div class="col-sm-12">
							<div class="single-clients">
								<img src="assets/img/clients/client-4.jpg" alt="" />
							</div>
						</div>
						<div class="col-sm-12">
							<div class="single-clients">
								<img src="assets/img/clients/client-5.jpg" alt="" />
							</div>
						</div>
						<div class="col-sm-12">
							<div class="single-clients">
								<img src="assets/img/clients/client-6.jpg" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";
export default {
	name: "Home",
	mounted() {
		jQuery(document).ready(function ($) {
			$('.hero').slick({
				dots: false,
				infinite: true,
				speed: 500,
				fade: !0,
				cssEase: 'linear',
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 8000,
				draggable: true,
				arrows: true,
				responsive: [
				{
				breakpoint: 1024,
				settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true
						}
				},
				{
				breakpoint: 768,
				settings: {
				draggable: true,
						}
				},
				{
				breakpoint: 600,
				settings: {
				slidesToShow: 1,
				draggable: true,
				slidesToScroll: 1
						}
				},
				{
				breakpoint: 480,
				settings: {
				slidesToShow: 1,
				draggable: true,
				slidesToScroll: 1
						}
				}
			
						]
			});
		});

				// blog slider script
				$(".product-slider").slick({
					dots: false,
					infinite: true,
					speed: 1000,
					slidesToShow: 3,
					slidesToScroll: 3,
					autoplay: true,
					fade: false,
					responsive: [
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 3,
								slidesToScroll: 3,
								infinite: true,
								dots: false,
								autoplay: true,
							},
						},
						{
							breakpoint: 600,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2,
								infinite: true,
								dots: true,
								arrows: false,
								autoplay: true,
							},
						},
						{
							breakpoint: 480,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								infinite: true,
								dots: true,
								arrows: false,
								autoplay: true,
							},
						},
					],
			});

		// clients
		$(".clients-active").owlCarousel({
			loop: true,
			nav: false,
			autoplay: true,
			responsive: {
				0: {
					items: 2,
				},
				320: {
					items: 2,
				},
				480: {
					items: 3,
				},
				767: {
					items: 5,
				},
				991: {
					items: 6,
				},
				1000: {
					items: 6,
				},
			},
		});
	},
	methods: {
		
	}
};
</script>
<style>
header {
  overflow: hidden;
}

.hero-text h2 {
  margin-bottom: 50px;
}

.hero-text .hero {
  position: relative;
}

.hero-text .hero .hero-slide a:hover span {
  color: #000000;
}

.hero .hero-slide img {
  width: 100%;
  height: 600px;
  object-fit: cover;
  object-position: top center;
}

.hero .hero-slide .header-content {
  top: 15%;
  margin-left: 8rem;
  max-width: 70%;
  width: 100%;
  padding: 2rem;
  color: #fff;
}
.header-content h1{
	color: #fff;
	font-size: 60px;
}

.slide-content {
  padding: 10px 20px 10px 0;
}

.slide-content .h1 {
  font-size: 62px;
}

/* .btn-primary {
  background-color: #5302FE;
  border-radius: 0;
} */

.slick-dots {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  border: 0;
  outline: none;
}

.slick-dots li button::before {
  font-size: 18px;
  color: #fff;
  opacity: 1;
}

.slick-active button {
  background: #000000;
}

.slider-sub-title{
	color: #d33327;
}

/** Text Animation **/

@-webkit-keyframes fadeInUpSD {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpSD {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUpSD {
  -webkit-animation-name: fadeInUpSD;
  animation-name: fadeInUpSD;
}

.slick-active .slide-content {
  animation-name: fadeInUpSD;
  animation-duration: 1s;
  opacity: 1;
  width: 100%;
  padding: 10px 20px 30px 0;
}

/* Media Queries */
@media (max-width:767px) {
	.hero .hero-slide .header-content {
		top: 2% !important;
		margin-left: 0rem !important;
		max-width: 100%;
		width: 100%;
		padding: 2rem;
		color: #fff;
	}
	.header-content h1{
		color: #fff;
		font-size: 30px;
	}
	.hero .hero-slide img{
		height: 420px;
	}
}

@media (max-width: 768px) {
  .hero .hero-slide .header-content {
		top: 2% !important;
		margin-left: 0rem !important;
		max-width: 100%;
		width: 100%;
		padding: 2rem;
		color: #fff;
	}
	.hero .hero-slide img{
		height: 400px;
	}
  .hero-text .hero .hero-slide a {
    padding-top: 0.8rem;
  }

  .hero-text .hero .hero-slide a span {
    font-size: 20px;
    margin-top: 0.5rem;
  }

  .hero .hero-slide .header-content {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: 0 auto;
  }
}
</style>
