<template>
  <div>
    <headersection />
    <router-view/>
    <footersection />
  </div>
</template>

<script>
import headersection from "../components/headersection";
import footersection from "../components/footersection";
export default {
  name: "Frame",
  components: {
    headersection,
    footersection,
  },
};
</script>

<style lang="scss" scoped></style>
